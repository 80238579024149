export const ManagerVerbs = Object.freeze({
  DropSubscribing: "dropSubscribing",
  GetMessagesByPhone: "messageByPhone",
  GetMessagesByTimestamp: "messageByTimestamp",
  GetMessagesByPhoneAndTimestamp: "messageByPhoneAndTimestamp",
  GetUserState: "getUserState",
  GetUserCursor: "getUserCursor",
  UserUnsubscribe: "userUnsubscribe",
  // ResendOffer = 'resendOffer',
  TextToAll: "textToAll",
  ResetUserState: "resetUserState",
  ResetUserCursor: "resetUserCursor",
  TextToPhone: "textToPhone",
});
