import React from "react";
import { Box, useTheme, Button, Fab, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import PaidIcon from "@mui/icons-material/Paid";
import DownloadIcon from "@mui/icons-material/Download";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ConfirmationFab from "../../buttons/ConfirmationFab";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost } from "../../utilFunctions/apiRequests";
import { CheckoutStatBox } from "../../boxComponents/CheckoutStatBox";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import StatBoxCheckoutXama from "../../boxComponents/StatBoxCheckoutXama";
import Header from "../../layoutComponents/Header";

const ConfirmDialog = (props) => {
	const { title, children, open, setOpen, onConfirm, confirmDisabled } = props;
	const theme = useTheme();

	const listSX = {
		backgroundColor: theme.palette.primary.main,
	};
	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
			<DialogTitle id="confirm-dialog" sx={listSX}>
				{title}
			</DialogTitle>
			<DialogContent sx={listSX}>{children}</DialogContent>
			<DialogActions sx={listSX}>
				<Button
					variant="contained"
					onClick={() => setOpen(false)}
					sx={{
						color: theme.palette.neutral.light,
						backgroundColor: theme.palette.danger.main,
					}}
				>
					Não!
				</Button>
				<Button
					variant="contained"
					disabled={confirmDisabled}
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}
					sx={{
						color: theme.palette.neutral.light,
						backgroundColor: theme.palette.primary.dark,
					}}
				>
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const AlertCheckoutMuve = ({ span, title, BigNumber, icon, additionalContent }) => {
	const theme = useTheme();

	return (
		<Box
			gridColumn={`span ${span}`}
			backgroundColor={theme.palette.primary.light}
			display="flex"
			alignItems="center"
			justifyContent="center"
			position="relative"
			sx={{ boxShadow: 1, minWidth: 300 }}
		>
			<Box width="100%" m="0 30px">
				<Box display="flex" justifyContent="space-between">
					<Box>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
							{title}
						</Typography>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.secondary.main }}>
							{BigNumber}
						</Typography>
					</Box>
					<Box>{icon}</Box>
				</Box>
				{additionalContent}
			</Box>
		</Box>
	);
};

const MuveCheckout = ({ checkoutList, refreshList, setRefreshList, firstSearch, accessToken, logout }) => {
	const theme = useTheme();
	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const downloadPayment = (filterDate, type, accessToken, logout) => {
		apiPost(
			"/romaneioCheckoutDownload",
			{ filterDate: filterDate, type: type, dbName: "xama", ops: "muve" },
			accessToken,
			logout
		).then((value) => {
			const url = window.URL.createObjectURL(new Blob([value.url]));
			const link = document.createElement("a");
			link.href = value.url;
			link.setAttribute("download", value.fileName);
			document.body.appendChild(link);
			link.click();
		});
	};

	const BoxesRowMuve = ({ array, refresFun }) => {
		return array.map((e) => (
			<Box key={`${e.checkout_date}${e.ops}${e.total_valor_face}`} m="20px">
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					position
					gridAutoRows="140px"
					gap="60px"
					marginBottom="2px"
				>
					<AlertCheckoutMuve
						span="2"
						BigNumber={e.entregadores_no_match}
						title="Entregadores sem Garantido ou Telefone"
						icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						additionalContent={
							e.entregadores_no_match > 0 && (
								<Fab
									color="primary"
									onClick={() => setConfirmOpen(true)}
									sx={{
										position: "absolute",
										bottom: "25px",
										left: "80%",
										transform: "translateX(-50%)",
									}}
								>
									<DownloadIcon />
								</Fab>
							)
						}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.entregadores_unicos}
						BigNumberType="integer"
						title={`Entregadores únicos`}
						icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.valor_total_garantido}
						BigNumberType="cents"
						title={`Total Valor Garantido`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.total_valor_face}
						BigNumberType="cents"
						title={`Valor de face acumulado`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<StatBoxCheckoutXama
						span="2"
						subtitle={"CHECKOUT"}
						refresFun={refresFun}
						confirmButtonDisabled={false}
						title={`${e.checkout_date.slice(0, 10)}`}
						ops={e.ops}
						checkoutDate={`${e.checkout_date.slice(0, 10)}`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				</Box>
			</Box>
		));
	};

	return (
		<>
			<Box mt="80px" marginBottom="30px">
				<BoxesRowMuve array={checkoutList} refresFun={setRefreshList} />
			</Box>

			<ConfirmDialog
				title="Confirmar Download"
				confirmDisabled={false}
				open={confirmOpen}
				setOpen={setConfirmOpen}
				onConfirm={() => {
					downloadPayment(checkoutList[0].checkout_date.slice(0, 10), "liquidado", accessToken, logout);
				}}
			>
				Deseja fazer o download do arquivo?
			</ConfirmDialog>

			{checkoutList.length === 0 && firstSearch === true && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title="Não há Checkouts de Muve para serem finalizados" subtitle="" />
				</Box>
			)}

			<Box>
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>
		</>
	);
};

export default MuveCheckout;
