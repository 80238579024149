import { Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Avatar(props) {
	const { width, height } = props;

	return (
		<Box>
			<AccountCircleIcon />
		</Box>
	);
}
