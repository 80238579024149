import { apiPost } from "../../utilFunctions/apiRequests";
import { ManagerVerbs } from "../../utilFunctions/managerVerbs";

const { REACT_APP_STAGE, REACT_APP_WHATSAPP_API } = process.env;

export const getUserState = async (clientName, phone, accessToken, logout, ops = "muve") => {
    const response = await apiPost(
        `/whatsapp-public-api-${REACT_APP_STAGE}/manager`,
        {
            clientName,
            phone,
            verb: ManagerVerbs.GetUserState,
            ops: ops,
        },
        accessToken,
        logout,
        REACT_APP_WHATSAPP_API
    ).catch((err) => console.log(err));

    return response?.response?.userState?.S;
};
