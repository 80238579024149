import axios from "axios";
import { logoutIf401 } from "./logoutIf400";

export const apiPostWithAlerts = async (
	endpoint,
	payload,
	apiToken,
	logout,
	url = process.env.REACT_APP_DASHBOARD_API,
	setAlertType = null,
	setAlertMsg = null,
	msgPrefix = ""
) => {
	try {
		const token = apiToken;
		if (token != null) {
			const data = await axios
				.post(url + endpoint, payload, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data.data !== undefined) {
						return res.data.data;
					} else {
						return res.data;
					}
				})
				.catch((err) => {
					logoutIf401(err, logout, token);

					// Exibir mensagem de erro, se setAlertType e setAlertMsg forem fornecidos
					if (setAlertType && setAlertMsg) {
						console.log(err);
						setAlertType("error");
						setAlertMsg(`${msgPrefix} Falhou. Error: ${err?.response?.data || err.message}`);
					}

					throw err; // Repassar o erro para o chamador
				});

			// Exibir mensagem de sucesso, se setAlertType e setAlertMsg forem fornecidos
			if (setAlertType && setAlertMsg) {
				setAlertType("success");
				setAlertMsg(`${msgPrefix} realizada com sucesso!`);
			}

			return data;
		}
	} catch (err) {
		console.error(err);
	}
	return null;
};

export const apiPost = (endpoint, payload, apiToken, logout, url = process.env.REACT_APP_DASHBOARD_API) => {
	try {
		const token = apiToken;
		if (token != null) {
			const data = axios
				.post(url + endpoint, payload, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data.data != undefined) {
						return res.data.data;
					} else {
						return res.data;
					}
				})
				.catch((err) => {
					console.log(err);
					logoutIf401(err, logout, token);
					return [];
				});

			return data;
		}
	} catch (err) {
		console.log(err);
	}
	return null;
};

export const apiGet = (endpoint, apiToken, logout, url = process.env.REACT_APP_DASHBOARD_API) => {
	try {
		const token = apiToken;
		if (token != null) {
			const data = axios
				.get(url + endpoint, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data.data != undefined) {
						return res.data.data;
					} else {
						return res.data;
					}
				})
				.catch((err) => {
					logoutIf401(err, logout, token);
					return [];
				});

			return data;
		}
	} catch (err) {
		console.log(err);
	}
	return null;
};
