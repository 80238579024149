import React from "react";
import { Box } from "@mui/material";
import MuveListaEntregadores from "../../global/opsSpecifics/muveComponents/muveListaEntregadores";
import RayoListaEntregadores from "../../global/opsSpecifics/rayoComponents/rayoListaEntregadores";
import LogusListaEntregadores from "../../global/opsSpecifics/logusComponents/logusListaEntregadores";
import AgilityListaEntregadores from "../../global/opsSpecifics/agilityComponents/agilityListaEntregadores";

const XamaListaEntregadores = ({ ops }) => {
	return (
		<Box m="20px">
			{ops === "muve" ? (
				<MuveListaEntregadores />
			) : ops === "rayo" ? (
				<RayoListaEntregadores />
			) : ops === "logus" ? (
				<LogusListaEntregadores />
			) : ops === "agility" ? (
				<AgilityListaEntregadores />
			) : null}
		</Box>
	);
};

export default XamaListaEntregadores;
