import { useContext, useEffect, useState } from "react";
import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { ConversationContext } from "../context/ConversationContext";
import Avatar from "../Avatar";
import MessageBalloon from "../MessageBalloon";
import { useTheme } from "@emotion/react";
import { getConversation } from "../service/getConversation";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllDeliverymanConversation } from "../service/getAllDeliverymanConversation";

export default function ConversationDetails({ setSelectedForm }) {
	const theme = useTheme();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const { logout } = useAuth0();
	const { conversationData, conversationsData, setConversations } = useContext(ConversationContext);
	const { clientName, phone, message: firstMessageHistory } = conversationData;
	const conversationIndex = conversationsData.findIndex((conversation) => conversation.phone === phone);
	const [messageHistory, setMessageHistory] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const isDropdownOpen = Boolean(anchorEl);

	useEffect(() => {
		setMessageHistory(firstMessageHistory ?? []);
	}, [conversationData, firstMessageHistory]);

	const handleDropdownClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleDropdownClose = () => {
		setAnchorEl(null);
	};

	const showAllMessages = async () => {
		try {
			const confirmation = window.confirm(
				"Tem certeza que deseja carregar todas as mensagens? Isso pode demorar bastante tempo."
			);

			if (confirmation) {
				const { olderMessages } = await getAllDeliverymanConversation(clientName, phone, accessToken, logout);
				conversationsData[conversationIndex].message = olderMessages;
				setConversations(conversationsData);
				setMessageHistory(olderMessages);
			}
			handleDropdownClose();
		} catch (err) {
			alert(`ConversationDetails - showAllMessages: ${err.message}`);
		}
	};

	const showMoreDeliverymanMessages = async () => {
		try {
			conversationData.endTimestamp = conversationData.startTimestamp;
			const oneDayInSeconds = 60 * 60 * 24;
			conversationData.startTimestamp -= oneDayInSeconds;
			const olderMessages = await getConversation(
				clientName,
				phone,
				conversationData.startTimestamp,
				conversationData.endTimestamp,
				accessToken,
				logout
			);
			const newMessageHistory = [...olderMessages.message, ...messageHistory];
			conversationData.message = newMessageHistory;
			setConversations(conversationsData);
			setMessageHistory(newMessageHistory);
			handleDropdownClose();
		} catch (error) {
			alert(`ConversationDetails - showMoreDeliverymanMessages: ${error.message}`);
		}
	};

	return (
		<Box display="flex" flexDirection="column" width="100%" height="100%" position="relative">
			{/* menssge details header */}
			<Box
				display="flex"
				justifyContent="space-between"
				px={4}
				bgcolor="#111b21"
				height={64}
				width="100%"
				alignItems="center"
			>
				<Box display="flex" alignItems="center" gap={2}>
					<Avatar width="30px" height="30px" image="avatar.jpg" />
					<Typography variant="h6" color="white">
						Entregador
					</Typography>
				</Box>
				<Box display="flex" alignItems="center" gap={2} color="#8696a0">
					<IconButton>
						<SearchIcon style={{ color: "#8696a0" }} />
					</IconButton>
					<IconButton onClick={handleDropdownClick}>
						<MoreVertIcon style={{ color: "#8696a0" }} />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={isDropdownOpen}
						onClose={handleDropdownClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<MenuItem onClick={showMoreDeliverymanMessages}>Carregar mais mensagens</MenuItem>
						<MenuItem onClick={showAllMessages}>Carregar todas as mensagens</MenuItem>
						<MenuItem onClick={() => setSelectedForm("single")}>Mostrar perfil</MenuItem>
						<MenuItem onClick={() => setSelectedForm("all")}>Mostrar todas as conversas</MenuItem>
					</Menu>
				</Box>
			</Box>

			{/* messages */}
			<Box
				flex="1"
				display="flex"
				flexDirection="column"
				overflow="auto"
				px={10}
				py={6}
				backgroundColor={theme.palette.primary.light}
			>
				{messageHistory.map((messageConversation, index) => {
					const { me, message, time, status } = messageConversation;
					return <MessageBalloon key={index} me={me} message={message} time={time} status={status} />;
				})}
			</Box>
		</Box>
	);
}
