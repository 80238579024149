import { apiPost } from "../../utilFunctions/apiRequests";
import { ManagerVerbs } from "../../utilFunctions/managerVerbs";

const { REACT_APP_STAGE, REACT_APP_WHATSAPP_API } = process.env;

export const resetCursor  = async (phone, clientName, ops, accessToken, logout) => await apiPost(
		`/whatsapp-public-api-${REACT_APP_STAGE}/manager`,
		{
      phone,
			clientName,
			verb: ManagerVerbs.ResetUserCursor,
			ops,
		},
		accessToken,
		logout,
		REACT_APP_WHATSAPP_API
	)
		.then((data) => data)
		.catch((err) => console.log(err))

