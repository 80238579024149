import { apiPost } from "../../utilFunctions/apiRequests";
import { ManagerVerbs } from "../../utilFunctions/managerVerbs";
import { handleMessages } from "../../utilFunctions/handleMessages";

export const getAllDeliverymanConversation = async (clientName, phone, accessToken, logout, ops = "muve") => {
	const { REACT_APP_STAGE, REACT_APP_WHATSAPP_API } = process.env;

	const messagesDynamoDB = await apiPost(
		`/whatsapp-public-api-${REACT_APP_STAGE}/manager`,
		{
			clientName,
			phone,
			verb: ManagerVerbs.GetMessagesByPhone,
			ops: ops,
		},
		accessToken,
		logout,
		REACT_APP_WHATSAPP_API
	)
		.then((data) => data)
		.catch((err) => console.log(err));
	if (!messagesDynamoDB.length) throw new Error("não existe mensagens");
	const formatedMessages = handleMessages(messagesDynamoDB);

	return Object.values(formatedMessages)[0];
};
