import Header from "../../global/layoutComponents/Header";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { apiPost, apiPostWithAlerts } from "../../global/utilFunctions/apiRequests";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import Table from "../../global/tableComponents/Table";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmationFab from "../../global/buttons/ConfirmationFab";
import TopAlert from "../../global/layoutComponents/TopAlert";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";

export default function ErrorPix(props) {
	const { accessToken, fundName, logout, ops, theme } = props;

	const [firstSearch, setFirstSearch] = useState(true);
	const [errorPixTable, setErrorPixTable] = useState([]);
	const [refreshList, setRefreshList] = useState(true);

	const [alertType, setAlertType] = useState("");
	const [alertMsg, setAlertMsg] = useState("");

	useEffect(() => {
		if ((fundName != "") & (accessToken != "")) {
			apiPost(
				"/gestorErrorPixViz",
				{
					dbName: fundName,
					ops: ops,
				},
				accessToken,
				logout
			)
				.then((value) => {
					setErrorPixTable(value.errorPix);
					setFirstSearch(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [accessToken, fundName, refreshList, logout]);

	useEffect(() => {
		setInterval(() => {
			setRefreshList((v) => !v);
		}, 1200000);
	}, []);

	const columnsOfAntecipacoesTable = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "sol_id", headerName: "SolId", flex: 1 },
		{
			field: "allowRetryPayment",
			headerName: "Retry",
			flex: 0.5,
			renderCell: (params) =>
				params.row.allowRetryPayment ? (
					<CheckIcon style={{ cursor: "pointer", color: theme.palette.success.main }} />
				) : (
					<CloseIcon style={{ cursor: "pointer", color: theme.palette.neutral.light }} />
				),
		},
		{
			field: "freezed",
			headerName: "Congelado",
			flex: 0.5,
			renderCell: (params) =>
				params.row.freezed ? (
					<AcUnitOutlinedIcon style={{ cursor: "pointer", color: theme.palette.neutral.light }} />
				) : (
					<></>
				),
		},
		{ field: "cnpjCpf", headerName: "Documento", flex: 1 },
		{ field: "telefone", headerName: "Telefone", flex: 1 },
		{
			field: "valorFace",
			headerName: "Valor Face",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "valorCompra",
			headerName: "Valor Compra",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "dataRomaneio",
			headerName: "Data Romaneio",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
		{
			field: "dataVencimento",
			headerName: "Data Vencimento",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
	];

	console.log(alertType, alertMsg);
	return (
		<>
			<>
				<Box textAlign="center" marginBottom="30px">
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
							marginTop: "20px",
						}}
					></hr>
				</Box>
			</>

			{errorPixTable && errorPixTable.length != 0 && fundName !== "" && firstSearch === false && alertType != "" && (
				<TopAlert alertType={alertType} alertMsg={alertMsg} setAlertType={setAlertType} setAlertMsg={setAlertMsg} />
			)}

			{firstSearch === true && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title={`Buscando em ops: ${ops.toUpperCase()} nos últimos 5 dias`} subtitle="" />
				</Box>
			)}

			{errorPixTable.length === 0 && fundName !== "" && firstSearch === false && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title={`Não há Error de Pix de ${ops.toUpperCase()} nos últimos 5 dias`} subtitle="" />
				</Box>
			)}

			{errorPixTable && errorPixTable.length != 0 && fundName !== "" && firstSearch === false && (
				<Box display="flex" sx={{ "& > :not(style)": { m: 0, mr: 0.2 } }}>
					<ConfirmationFab
						disabled={false}
						icon={<DownloadIcon />}
						confirmMsg="Soliticar novos arquivos de pagamento (E-MAIL)?"
						confirmFun={() => {
							const resp = apiPostWithAlerts(
								"/gestorErrorPixDownload",
								{
									dbName: fundName,
									ops: ops,
								},
								accessToken,
								logout,
								undefined,
								setAlertType,
								setAlertMsg,
								`Solicitação de ${ops.toUpperCase()}`
							);
						}}
					/>
				</Box>
			)}

			{errorPixTable && errorPixTable.length != 0 && fundName !== "" && firstSearch === false && (
				<Box>
					<Table
						height="20vh"
						checkBoxSelection={false}
						jsonData={errorPixTable}
						columns={columnsOfAntecipacoesTable}
						fileName={`pixError_${ops}`}
					/>
				</Box>
			)}

			{/* LINHA */}
			<Box textAlign="center" marginBottom="30px">
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
						marginTop: "20px",
					}}
				></hr>
			</Box>
		</>
	);
}
