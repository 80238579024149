import Header from "../../global/layoutComponents/Header";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import { Box, useTheme, Typography, Fab } from "@mui/material";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfirmDialog } from "../../global/inputComponents/ConfirmDialog";
import PaidIcon from "@mui/icons-material/Paid";
import StorageIcon from "@mui/icons-material/Storage";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";

const ConfirmationFab = (props) => {
	const theme = useTheme();
	const { disabled, icon, confirmMsg, confirmFun } = props;
	const [confirmOpen, setConfirmOpen] = useState(false);

	return (
		<Box display="flex" sx={{ m: 1, position: "relative" }}>
			<Fab color="primary" aria-label="add" disabled={disabled} onClick={() => setConfirmOpen(true)}>
				{icon}
			</Fab>
			<ConfirmDialog title="" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={confirmFun}>
				<Typography
					variant="h3"
					fontWeight="bold"
					sx={{
						color: theme.palette.neutral.light,
						marginBottom: "10px",
					}}
				>
					Confirmação
				</Typography>

				<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
					{confirmMsg}
				</Typography>
			</ConfirmDialog>
		</Box>
	);
};

const StatBoxCheckoutResolution = ({
	span,
	title,
	subtitle,
	refresFun,
	cnabDisabled,
	submitDisabled,
	dbName,
	ops,
	filterDate,
}) => {
	const theme = useTheme();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const user = useSelector((state) => state.auth0.user);
	const { logout } = useAuth0();

	return (
		<Box
			gridColumn={`span ${span}`}
			backgroundColor={theme.palette.primary.light}
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{ boxShadow: 1, minWidth: 300 }}
		>
			<Box width="100%" m="0 30px">
				<Box display="flex" justifyContent="space-between">
					<Box>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.neutral.light }}>
							{title}
						</Typography>
						<Typography variant="h3" fontWeight="bold" sx={{ color: theme.palette.secondary.main }}>
							{subtitle}
						</Typography>
					</Box>

					<Box display="flex" sx={{ "& > :not(style)": { m: 0, mr: 0.2 } }}>
						<ConfirmationFab
							disabled={cnabDisabled}
							icon={<DriveFileMoveIcon />}
							confirmMsg="Criar Cnab de baixa?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorBaixaDocStart",
									{
										dbName: dbName,
										filterDate: filterDate,
										ops: ops,
										userInfo: {
											email: user.email,
										},
									},
									accessToken,
									logout
								);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
							}}
						/>
						<ConfirmationFab
							disabled={submitDisabled}
							icon={<StorageIcon />}
							confirmMsg="Realizar baixa no banco de dados?"
							confirmFun={() => {
								const resp = apiPost(
									"/gestorSubmitBaixaDb",
									{
										dbName: dbName,
										filterDate: filterDate,
										ops: ops,
										userInfo: {
											email: user.email,
										},
									},
									accessToken,
									logout
								);

								console.log(resp.data);

								const timeout = setTimeout(() => {
									refresFun((e) => !e);
								}, 3000);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const BaixaResolutionBoxes = (props) => {
	const { array, dbName, refresFun, ops } = props;
	const theme = useTheme();

	return array.map((e) => {
		return (
			<Box key={`${e.data_vencimento}${e.valor_face}`}>
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(80px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<CheckoutStatBox
							span="2"
							BigNumber={ops}
							BigNumberType="asis"
							title={`Ops`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.valor_face_bonus}
							BigNumberType="cents"
							title={`Valor de Face Bônus`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}{" "}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.valor_face}
							BigNumberType="cents"
							title={`Valor de Face`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.desagio_bonus}
							BigNumberType="cents"
							title={`Deságio Bônus`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<CheckoutStatBox
							span="2"
							BigNumber={e.desagio}
							BigNumberType="cents"
							title={`Deságio`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<StatBoxCheckoutResolution
							span="2"
							subtitle={"DATA VENCIMENTO"}
							refresFun={refresFun}
							ops={ops}
							cnabDisabled={false}
							submitDisabled={new Date() <= Date.parse(e.data_vencimento)}
							title={`${e.data_vencimento.slice(0, 10)}`}
							dbName={dbName}
							filterDate={`${e.data_vencimento.slice(0, 10)}`}
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			</Box>
		);
	});
};

export default function BaixaXamaViewer(props) {
	const theme = useTheme();

	const [firstSearch, setFirstSearch] = useState(false);
	const [baixaListAgility, setBaixaAgilityList] = useState([]);
	const [baixaListMuve, setBaixaMuveList] = useState([]);
	const [refreshList, setRefreshList] = useState(true);

	useEffect(() => {
		if ((props.fundName != "") & (props.accessToken != "")) {
			apiPost(
				"/gestorBaixaList",
				{
					dbName: props.fundName,
					ops: "agility",
				},
				props.accessToken,
				props.logout
			)
				.then((value) => {
					setBaixaAgilityList(value);
					setFirstSearch(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.accessToken, props.fundName, refreshList]);

	console.log(baixaListAgility);
	console.log(baixaListMuve);

	useEffect(() => {
		if ((props.fundName != "") & (props.accessToken != "")) {
			apiPost(
				"/gestorBaixaList",
				{
					dbName: props.fundName,
					ops: "muve",
				},
				props.accessToken,
				props.logout
			)
				.then((value) => {
					setBaixaMuveList(value);
					setFirstSearch(true);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.accessToken, props.fundName, refreshList]);

	useEffect(() => {
		setInterval(() => {
			setRefreshList((v) => !v);
		}, 20000);
	}, []);

	return (
		<>
			<>
				{/* LINHA */}
				<Box textAlign="center" mt="30px" marginBottom="30px">
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
						}}
					></hr>
				</Box>

				{/* agility */}

				{baixaListAgility.length != 0 && (
					<Box mt="80px" marginBottom="30px">
						<BaixaResolutionBoxes
							ops="agility"
							array={baixaListAgility}
							dbName={props.fundName}
							refresFun={setRefreshList}
						/>
					</Box>
				)}

				{baixaListAgility.length == 0 && props.fundName != "" && firstSearch == true && (
					<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
						<Header title="Não há Baixa de Agility" subtitle="" />
					</Box>
				)}

				<Box>
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
						}}
					></hr>
				</Box>
			</>

			{/* LINHA */}
			<Box textAlign="center" mt="30px" marginBottom="30px">
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>

			<>
				{/* MUVE */}

				{baixaListMuve.length != 0 && (
					<Box mt="80px" marginBottom="30px">
						<BaixaResolutionBoxes ops="muve" array={baixaListMuve} dbName={props.fundName} refresFun={setRefreshList} />
					</Box>
				)}

				{baixaListMuve.length == 0 && props.fundName != "" && firstSearch == true && (
					<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
						<Header title="Não há Baixa para Muve" subtitle="" />
					</Box>
				)}

				<Box>
					<hr
						style={{
							border: "dotted 2px",
							color: theme.palette.secondary.main,
							backgroundColor: theme.palette.primary.main,
						}}
					></hr>
				</Box>
			</>
		</>
	);
}
