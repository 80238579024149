import React from "react";
import { Box, useTheme } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import PaidIcon from "@mui/icons-material/Paid";
import { CheckoutStatBox } from "../../boxComponents/CheckoutStatBox";
import StatBoxCheckoutXama from "../../boxComponents/StatBoxCheckoutXama";
import Header from "../../layoutComponents/Header";

const LogusCheckout = ({ checkoutList, refreshList, setRefreshList, firstSearch }) => {
	const theme = useTheme();

	const BoxesRowLogus = ({ array, refresFun }) => {
		return array.map((e) => (
			<Box key={`${e.checkout_date}${e.ops}${e.total_valor_face}`}>
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					gap="60px"
					marginBottom="2px"
				>
					<CheckoutStatBox
						span="2"
						BigNumber={e.ops}
						BigNumberType="asIs"
						title={`Operação`}
						icon={<LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.entregadores_unicos}
						BigNumberType="integer"
						title={`Entregadores únicos`}
						icon={<PeopleIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.valor_total_garantido}
						BigNumberType="cents"
						title={`Total Valor Garantido`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<CheckoutStatBox
						span="2"
						BigNumber={e.total_valor_face}
						BigNumberType="cents"
						title={`Valor de face acumulado`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>

					<StatBoxCheckoutXama
						span="2"
						subtitle={"CHECKOUT"}
						refresFun={refresFun}
						confirmButtonDisabled={false}
						title={`${e.checkout_date.slice(0, 10)}`}
						ops={e.ops}
						checkoutDate={`${e.checkout_date.slice(0, 10)}`}
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				</Box>
			</Box>
		));
	};

	return (
		<>
			<Box mt="80px" marginBottom="30px">
				<BoxesRowLogus array={checkoutList} refresFun={setRefreshList} />
			</Box>

			{checkoutList.length === 0 && firstSearch === true && (
				<Box display="flex" justifyContent="center" alignItems="center" mt="30px">
					<Header title="Não há Checkouts de logus para serem finalizados" subtitle="" />
				</Box>
			)}

			<Box>
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>
		</>
	);
};

export default LogusCheckout;
