import Header from "../../global/layoutComponents/Header";
import { CheckoutStatBox } from "../../global/boxComponents/CheckoutStatBox";
import { Box, useTheme, Typography, Fab, Grid } from "@mui/material";
import { SelectForm } from "../../global/inputComponents/SelectForm";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorPix from "./opsErrorPix.jsx";

import { apiGet } from "../../global/utilFunctions/apiRequests";

const GestorErrorPix = () => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);
	const user = useSelector((state) => state.auth0.user);

	const [fundName, setFundName] = useState("");
	const [fundOptions, setFundOptions] = useState([]);

	useEffect(() => {
		if (accessToken != "") {
			apiGet("/availableFunds", accessToken, logout)
				.then((value) => {
					value.map((e) => {
						delete Object.assign(e, { key: e.dbname })["dbname"];
						delete Object.assign(e, { value: e.fundname })["fundname"];
					});
					setFundOptions(value);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [accessToken, logout]);

	switch (fundName) {
		case "xpto_prod":
		case "xpto_dev":
			var content = <></>;
			break;
		case "xama_prod":
		case "xama_dev":
			var content = (
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<ErrorPix fundName={fundName} ops="muve" logout={logout} theme={theme} accessToken={accessToken} />
					</Grid>
					<Grid item xs={12}>
						<ErrorPix fundName={fundName} ops="agility" logout={logout} theme={theme} accessToken={accessToken} />
					</Grid>
					<Grid item xs={12}>
						<ErrorPix fundName={fundName} ops="indrive" logout={logout} theme={theme} accessToken={accessToken} />
					</Grid>
					<Grid item xs={12}>
						<ErrorPix fundName={fundName} ops="logus" logout={logout} theme={theme} accessToken={accessToken} />
					</Grid>
				</Grid>
			);
			break;
		case "rondolog_prod":
		case "rondolog_dev":
			var content = <></>;
			break;
		case "teste":
			var content = <></>;

			break;
		default:
			var content = (
				<Box m="20px">
					<Header title="Selecione um Fundo" subtitle="" />
				</Box>
			);
	}

	return (
		<>
			<Box m="20px">
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="0"
					position
					gap="20px"
					mt="60px"
					marginBottom="40px"
				>
					<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
						<Box>
							<Header title="ERROR PIX" subtitle="Resolver PIX pendentes" />
						</Box>
					</Box>

					<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
						<SelectForm label="Fundos" varValue={fundName} setFun={setFundName} optionArray={fundOptions} />
					</Box>
				</Box>
				<>{content}</>
			</Box>
		</>
	);
};

export default GestorErrorPix;
