import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import MuveAntecipacaoViz from "../../global/opsSpecifics/muveComponents/muveAntecipacaoViz";
import RayoAntecipacaoViz from "../../global/opsSpecifics/rayoComponents/rayoAntecipacaoViz";
import IndriveAntecipacaoViz from "../../global/opsSpecifics/indriveComponents/indriveAntecipacaoViz";
import LogusAntecipacaoViz from "../../global/opsSpecifics/logusComponents/logusAntecipacaoViz";
import AgilityAntecipacaoViz from "../../global/opsSpecifics/agilityComponents/agilityAntecipacaoViz";

const XamaAntecipacaoViz = ({ ops }) => {
	const theme = useTheme();
	const datetime = new Date().toISOString().slice(0, 10);
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [romaneioAntecipacaoViz, setRomaneioAntecipacaoViz] = useState(null);
	const [datePicked, setDatePicker] = useState(dayjs(datetime));

	useEffect(() => {
		if (datePicked != null && accessToken !== "") {
			apiPost(
				"/romaneioAntecipacaoViz",
				{ filterDate: datePicked.format("YYYY-MM-DD"), dbName: "xama", ops: ops },
				accessToken,
				logout
			).then((value) => {
				setRomaneioAntecipacaoViz(value);
			});
		}
	}, [accessToken, datePicked, logout]);

	return (
		<Box m="20px">
			{ops === "muve" && (
				<MuveAntecipacaoViz
					romaneioAntecipacaoViz={romaneioAntecipacaoViz}
					datePicked={datePicked}
					setDatePicker={setDatePicker}
				/>
			)}
			{ops === "rayo" && (
				<RayoAntecipacaoViz
					romaneioAntecipacaoViz={romaneioAntecipacaoViz}
					datePicked={datePicked}
					setDatePicker={setDatePicker}
				/>
			)}

			{ops === "indrive" && (
				<IndriveAntecipacaoViz
					romaneioAntecipacaoViz={romaneioAntecipacaoViz}
					datePicked={datePicked}
					setDatePicker={setDatePicker}
				/>
			)}
			{ops === "logus" && (
				<LogusAntecipacaoViz
					romaneioAntecipacaoViz={romaneioAntecipacaoViz}
					datePicked={datePicked}
					setDatePicker={setDatePicker}
				/>
			)}
			{ops === "agility" && (
				<AgilityAntecipacaoViz
					romaneioAntecipacaoViz={romaneioAntecipacaoViz}
					datePicked={datePicked}
					setDatePicker={setDatePicker}
				/>
			)}
		</Box>
	);
};

export default XamaAntecipacaoViz;
