import {
  Box,
  Typography,
  useTheme,
  MenuItem,
  ListSubheader,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Header from "../../global/layoutComponents/Header";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colorTokens } from "../../theme";
import { useState } from "react";

const CustomFormControl = ({ selectedOps, setSelectedOps, opsOptions }) => {
  const theme = useTheme();

  return (
    <MuiFormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel
        sx={{
          "&.Mui-focused": {
            borderColor: theme.palette.neutral.light,
            color: theme.palette.neutral.light,
            transform: "translate(0, -25px)",
          },
        }}
      >
        Selecione uma operação
      </InputLabel>
      <Select
        sx={{
          "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.neutral.light,
            },
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: theme.palette.primary.main,
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
        value={selectedOps}
        label="Operações"
        onChange={(e) => {
          setSelectedOps(e.target.value);
        }}
      >
        {opsOptions.map((option) =>
          option.type === "divider" ? (
            <ListSubheader
              key={option.key}
              sx={{
                backgroundColor: option.backgroundColor || "#283444",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {option.value}
            </ListSubheader>
          ) : (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          )
        )}
      </Select>
    </MuiFormControl>
  );
};

const FaqQuestion = ({ question, answer }) => {
  const theme = useTheme();
  const colors = colorTokens(theme.palette.mode);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.neutral.dark }} />
        }
        sx={{ backgroundColor: theme.palette.neutral.light }}
      >
        <Typography
          color={theme.palette.neutral.dark}
          variant="h5"
          fontWeight="bold"
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: colors.grey[100],
          borderBlockColor: theme.palette.background,
        }}
      >
        {Array.isArray(answer) ? (
          <ul
            style={{
              listStyleType: "disc",
              color: "black",
              paddingLeft: "20px",
            }}
          >
            {answer.map((item, index) => (
              <li key={index} style={{ color: "black", marginBottom: "8px" }}>
                <Typography
                  color={theme.palette.neutral.dark}
                  variant="h6"
                  fontWeight={item.bold ? "bold" : "normal"}
                >
                  {typeof item === "string" ? item : item.text}
                </Typography>
                {item.subItems && Array.isArray(item.subItems) && (
                  <div style={{ paddingLeft: "20px", marginTop: "4px" }}>
                    {item.subItems.map((subItem, subIndex) => (
                      <Typography
                        key={subIndex}
                        color={theme.palette.neutral.dark}
                        variant="body1"
                        fontWeight={subItem.bold ? "bold" : "normal"}
                      >
                        {typeof subItem === "string" ? subItem : subItem.text}
                      </Typography>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <Typography color={theme.palette.neutral.dark} variant="h6">
            {answer}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const faqData = {
  xama: [
    {
      question: "Informações Cadastrais",
      answer: [
        {
          text: "Razão social:",
          subItems: [
            { text: "XAMA! FUNDO DE INVESTIMENTO EM DIREITOS CREDITORIOS NÃO-PADRONIZADOS", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "47.669.160/0001-91", bold: false },
          ],
          bold: true,
        },
        {
          text: "Banco:",
          subItems: [
            { text: "463", bold: false },
          ],
          bold: true,
        },
        {
          text: "Agência:",
          subItems: [
            { text: "0001", bold: false },
          ],
          bold: true,
        },
        {
          text: "Conta Corrente:",
          subItems: [
            { text: "11198-2", bold: false },
          ],
          bold: true,
        },
      ],
    },
    {
      question: "Contatos do Custodiante (Azumi)",
      answer: [
        {
          text: "Administrador e Custodiante:",
          subItems: [
            { text: "Azumi Distribuidora de Títulos e Valores Mobiliários LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "40.434.681/0001-10", bold: false },
          ],
          bold: true,
        },
        {
          text: "Liquidação(Toninho):",
          subItems: [
            { text: "str.liquidacao@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Administração Fiduciária:",
          subItems: [
            { text: "0001", bold: false },
          ],
          bold: true,
        },
        {
          text: "Custódia:",
          subItems: [
            { text: "custodia@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Controladoria:",
          subItems: [
            { text: "controladoria@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Operações:",
          subItems: [
            { text: "jessica.moreira@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Jurídico:",
          subItems: [
            { text: "juridico@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Despesas Fundos:",
          subItems: [
            { text: "despesasfundos@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Supervisor de operações, cadastro e conta escrow:",
          subItems: [
            { text: "saul.barroso@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Cadastro de cotista:",
          subItems: [
            { text: "cadastrodigital@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Problemas relacionados ao acesso do cotista à conta:",
          subItems: [
            { text: "Miguel Patti Pires - Assessor de Investimentos - (11) 95852-3271 - miguel.pires@bancoazumi.com.br (Solicitar que ele entre em contato com o cotista)", bold: false },
          ],
          bold: true,
        },
        {
          text: "Problemas relacionados ao Sinqia:",
          subItems: [
            { text: "felipe.cot@azumidtvm.com.br", bold: false },
          ],
          bold: true,
        },
        {
          text: "Taxa de Gestão, ADM e Custódia:",
          subItems: [
            { text: "eurico.mendes@azumidtvm.com.br - (11) 98789-3567", bold: false },
          ],
          bold: true,
        },
        {
          text: "Contato Geral:",
          subItems: [
            { text: "(11) 2626-9780", bold: false },
          ],
          bold: true,
        },
      ],
    },
  ],
  agility: [
    {
      question: "Dados Agility",
      answer: [
        {
          text: "Razão social:",
          subItems: [
            { text: "AGILITY SOLUÇÃO LOGÍSTICA E TRANSPORTE LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "50.269.274/0001-59", bold: false },
          ],
          bold: true,
        },
        {
          text: "Endereço:",
          subItems: [
            { text: "Rua Manoel Silva, número 51, bairro Copacabana, Cidade de Belo Horizonte, Estado de Minas Gerais, CEP 31.550-190", bold: false },
          ],
          bold: true,
        },
        {
          text: "E-mail:",
          subItems: [
            { text: "icaro.schimidt@agilitylogistica.com", bold: false },
          ],
          bold: true,
        },
        {
          text: "Telefone:",
          subItems: [
            { text: "(31) 9798-2368", bold: false },
          ],
          bold: true,
        },
        {
          text: "Ciclo de faturamento:",
          subItems: [
            { text: "15 + 15", bold: false },
          ],
          bold: true,
        },
        {
          text: "Data de Pagamento:",
          subItems: [
            { text: "Dia 15 e 31", bold: false },
          ],
          bold: true,
        },
      ],
    },
    {
      question: "Dados Conta Escrow",
      answer: [
        {
          text: "Razão Social:",
          subItems: [
            { text: "AGILITY SOLUCAO LOGISTICA E TRANSPORTE LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "50.269.274/0001-59", bold: false },
          ],
          bold: true,
        },
        {
          text: "Banco:",
          subItems: [
            { text: "463", bold: false },
          ],
          bold: true,
        },
        {
          text: "Agência:",
          subItems: [
            { text: "0004", bold: false },
          ],
          bold: true,
        },
        {
          text: "Conta Escrow:",
          subItems: [
            { text: "14134-4", bold: false },
          ],
          bold: true,
        }
      ],
    },
    {
      question: "Dados da conta de livre movimentação",
      answer: [
        {
          text: "Razão Social:",
          subItems: [
            { text: "AGILITY SOLUCAO LOGISTICA E TRANSPORTE LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "50.269.274/0001-59", bold: false },
          ],
          bold: true,
        },
        {
          text: "Banco:",
          subItems: [
            { text: "Nubank - 0260", bold: false },
          ],
          bold: true,
        },
        {
          text: "Agência:",
          subItems: [
            { text: "0001", bold: false },
          ],
          bold: true,
        },
        {
          text: "Conta Corrente:",
          subItems: [
            { text: "86244062-4", bold: false },
          ],
          bold: true,
        }
      ],
    },
    {
      question: "Assinantes do contrato executivo",
      answer: [
        {
          text: "Ícaro Moura Schimidt:",
          subItems: [
            { text: "CPF: 098.541.316-61 – icaro.schimidt@agilitylogistica.com", bold: false },
          ],
          bold: true,
        },
        {
          text: "Nurrielly de Deus Martins Schimidt:",
          subItems: [
            { text: "CPF: 112.146.396-74 - icaro.schimidt@agilitylogistica.com", bold: false },
          ],
          bold: true,
        },
        {
          text: "Sobre as assinaturas falar com Ícaro (31) 9798-2368",
          bold: true,
        },
      ],
    },
    {
      question: "Documentação necessária para abertura da conta escrow",
      answer: [
        {
          text: "Cartão CNPJ",
          bold: false,
        },
        {
          text: "Contrato Social",
          bold: false,
        },
        {
          text: "Declaração de faturamento dos últimos 12 meses assinada pelo contador",
          bold: false,
        },
        {
          text: "Documento pessoal dos sócios, dos fiadores e dos cônjuges (companheiros em união estável, se houver)",
          bold: false,
        },
        {
          text: "Comprovante de residência da empresa",
          bold: false,
        },
        {
          text: "Comprovante de residência dos sócios",
          bold: false,
        },
        {
          text: "Dados da conta de livre movimentação",
          bold: false,
        },
        {
          text: "E-mail da empresa para envio do termo de abertura de conta",
          bold: false,
        },
        {
          text: "Enviar toda a documentação acima para: saul.barroso@azumidtvm.com.br e cadastrodigital@azumidtvm.com.br",
          bold: true,
        }
      ],
    },
    {
      question: "Arquivos de input",
      answer: [
        {
          text: "Arquivo .XLSX",
          subItems: [
            { text: "valor", bold: false },
            { text: "data", bold: false },
            { text: "cpf", bold: false },
            { text: "telefone", bold: false },
            { text: "Os valores são agrupados, então há uma linha para cada entregador.", bold: true },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "38.184.489/0001-71", bold: false },
          ],
          bold: true,
        },
      ],
    },
  ],
  muve: [
    {
      question: "Dados Muve",
      answer: [
        {
          text: "Razão social:",
          subItems: [
            { text: "MVP ENTREGAS RAPIDAS LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "38.184.489/0001-71", bold: false },
          ],
          bold: true,
        },
        {
          text: "Endereço:",
          subItems: [
            { text: "Avenida C-255, nº 400, sala 206, edifício Eldorado Business, Cidade de Goiânia, Estado de Goiás, CEP 74280-01", bold: false },
          ],
          bold: true,
        },
        {
          text: "E-mail:",
          subItems: [
            { text: "financeiro@muve.delivery", bold: false },
          ],
          bold: true,
        },
        {
          text: "Telefone:",
          subItems: [
            { text: "(62) 99979-3790", bold: false },
          ],
          bold: true,
        },
        {
          text: "Ciclo de faturamento:",
          subItems: [
            { text: "15 + 10", bold: false },
          ],
          bold: true,
        },
        {
          text: "Data de Pagamento:",
          subItems: [
            { text: "Dia 10 e 25", bold: false },
          ],
          bold: true,
        },
      ],
    },
    {
      question: "Dados Conta Escrow",
      answer: [
        {
          text: "Razão Social:",
          subItems: [
            { text: "MVP ENTREGAS RAPIDAS LTDA", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "38.184.489/0001-71", bold: false },
          ],
          bold: true,
        },
        {
          text: "Banco:",
          subItems: [
            { text: "463", bold: false },
          ],
          bold: true,
        },
        {
          text: "Agência:",
          subItems: [
            { text: "0004", bold: false },
          ],
          bold: true,
        },
        {
          text: "Conta Escrow:",
          subItems: [
            { text: "1475-4", bold: false },
          ],
          bold: true,
        }
      ],
    },
    {
      question: "Dados da conta de livre movimentação",
      answer: [
        {
          text: "Razão Social:",
          subItems: [
            { text: "MUVE DELIVERY", bold: false },
          ],
          bold: true,
        },
        {
          text: "CNPJ:",
          subItems: [
            { text: "38.184.489/0001-71", bold: false },
          ],
          bold: true,
        },
        {
          text: "Banco:",
          subItems: [
            { text: "Itaú - 341", bold: false },
          ],
          bold: true,
        },
        {
          text: "Agência:",
          subItems: [
            { text: "0869", bold: false },
          ],
          bold: true,
        },
        {
          text: "Conta Corrente:",
          subItems: [
            { text: "99347-7", bold: false },
          ],
          bold: true,
        }
      ],
    },
    {
      question: "Assinantes do contrato executivo",
      answer: [
        {
          text: "Murilo Campos Freire Botelho:",
          subItems: [
            { text: "CPF: 037.978.101-80 – financeiro@muve.delivery", bold: false },
          ],
          bold: true,
        },
        {
          text: "Vanessa Pereira Tannous:",
          subItems: [
            { text: "CPF: 121.871.476-06 – financeiro@muve.delivery", bold: false },
          ],
          bold: true,
        },
        {
          text: "Victor Ribeiro do Prado Oliveira:",
          subItems: [
            { text: "CPF: 700.430.981-93 – financeiro@muve.delivery", bold: false },
          ],
          bold: true,
        },
        {
          text: "Sobre as assinaturas falar com Juliana (62) 98523-2601",
          bold: true,
        },
      ],
    },
    {
      question: "Documentação necessária para abertura da conta escrow",
      answer: [
        {
          text: "Cartão CNPJ",
          bold: false,
        },
        {
          text: "Contrato Social",
          bold: false,
        },
        {
          text: "Declaração de faturamento dos últimos 12 meses assinada pelo contador",
          bold: false,
        },
        {
          text: "Documento pessoal dos sócios, dos fiadores e dos cônjuges (companheiros em união estável, se houver)",
          bold: false,
        },
        {
          text: "Comprovante de residência da empresa",
          bold: false,
        },
        {
          text: "Comprovante de residência dos sócios",
          bold: false,
        },
        {
          text: "Dados da conta de livre movimentação",
          bold: false,
        },
        {
          text: "E-mail da empresa para envio do termo de abertura de conta",
          bold: false,
        },
        {
          text: "Enviar toda a documentação acima para: saul.barroso@azumidtvm.com.br e cadastrodigital@azumidtvm.com.br",
          bold: true,
        }
      ],
    },
    {
      question: "Arquivos de input",
      answer: [
        {
          text: "Informações essenciais do cliente:",
          subItems: [
            { text: "CPF/CNPJ", bold: false },
            { text: "Data do romaneio", bold: false },
            { text: "Valor", bold: false },
            { text: "Telefone", bold: false },
          ],
          bold: true,
        },
        {
          text: "Arquivos enviados pela MUVE:",
          subItems: [
            { text: "Arquivo de pedidos PickNGo: Aqui tem informações dos pedidos, como data, valor, id de entrega, id restaurante etc.", bold: false },
            { text: "Arquivo Garantido: Aqui possui as informações referente ao valor garantido que é adicionado ao entregador. Aproveitamos esse arquivo para obter informações de telefone", bold: false },
            { text: "As mensagens somente são enviadas para entregadores que tenham CPF e TELEFONE no GARANTIDO", bold: true }
          ],
          bold: true,
        }
      ],
    },
  ],
};

const Faq = () => {
  const [selectedOps, setSelectedOps] = useState("");

  const opsOptions = [
    {
      type: "divider",
      key: "xama",
      value: "Xama",
      backgroundColor: "#283444",
    },
    { key: "xama", value: "Dados Gerais do Fundo" },
    { key: "agility", value: "Agility" },
    { key: "muve", value: "Muve" },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="FAQ" subtitle="Dúvidas frequentes" />
      </Box>
      <Box mt={4}>
        <CustomFormControl
          selectedOps={selectedOps}
          setSelectedOps={setSelectedOps}
          opsOptions={opsOptions}
        />
        {selectedOps &&
          faqData[selectedOps]?.map((faq, index) => (
            <FaqQuestion key={index} question={faq.question} answer={faq.answer} />
          ))}
      </Box>
    </Box>
  );
};

export default Faq;
