import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const TopAlert = ({ alertType, alertMsg, setAlertType, setAlertMsg }) => {
	const [open, setOpen] = useState(true);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setAlertType("");
		setAlertMsg("");
		setOpen(false);
	};

	// Check if alertType is valid
	const validAlertTypes = ["success", "error", "warning", "info"];
	if (!validAlertTypes.includes(alertType)) {
		console.warn(`Invalid alertType: ${alertType}`);
		return null;
	}

	return (
		<>
			{/* Snackbar com o alerta no topo */}
			<Snackbar
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }} // Posiciona no topo
			>
				<Alert onClose={handleClose} severity={alertType} variant="filled">
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

export default TopAlert;
