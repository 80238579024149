import { useState, useContext } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses, menuClasses } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { colorTokens } from "../../theme";
import { sidebarContext } from "./SidebarContext";
import { Link, NavLink } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DomainVerificationOutlinedIcon from "@mui/icons-material/DomainVerificationOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import PixOutlinedIcon from "@mui/icons-material/PixOutlined";

const SubMenuItem = (props, icon) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);
	console.log("MEnuClasses");
	console.log(menuClasses);
	return (
		<SubMenu
			label={props.label}
			icon={props.icon}
			rootStyles={{
				["& > ." + menuClasses.button]: {
					background: theme.palette.primary.dark,
					backgroundColor: theme.palette.primary.dark,
					"&:hover": {
						background: colors.azulPlanalto[500],
						backgroundColor: theme.palette.primary.light,
					},
				},
				["." + menuClasses.subMenuContent]: {
					background: theme.palette.primary.light,
					backgroundColor: theme.palette.primary.dark,
					"&:hover": {
						backgroundColor: theme.palette.primary.dark,
					},
				},
			}}
		>
			{props.children}
		</SubMenu>
	);
};
const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();

	const colors = colorTokens(theme.palette.mode);
	const { isToggled, setToggled } = useContext(sidebarContext);

	return (
		<MenuItem
			component={<Link to={to} className="link" />}
			active={selected === title}
			onClick={() => {
				setSelected(title);
				setToggled(false);
			}}
			icon={icon}
		>
			<Typography>{title}</Typography>
		</MenuItem>
	);
};

const Sidebar2 = (props) => {
	const theme = useTheme();
	const colors = colorTokens(theme.palette.mode);
	const { isToggled, setToggled } = useContext(sidebarContext);
	const [selected, setSelected] = useState("Home");
	const screens = process.env.REACT_APP_SCREENS.split(" ");

	return (
		<div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
			<Sidebar
				width="300px"
				rootStyles={{
					[`.${sidebarClasses.container}`]: {
						background: theme.palette.primary.dark,
						padding: "5px 35px 5px 20px !important",
					},

					[`.${sidebarClasses.backdrop}`]: {
						background: colors.azulPlanalto[500],
						backgroundColor: "transparent",
					},
					[`borderColor`]: theme.palette.primary.dark,
					[`&.${sidebarClasses.broken}`]: {
						zIndex: "2000 !important",
					},

					[`.${sidebarClasses.broken}`]: {
						background: colors.azulPlanalto[500],
						backgroundColor: "transparent",
						padding: "5px 35px 5px 20px !important",
					},
				}}
				rtl={false}
				// collapsed={isCollapsed}
				onBackdropClick={() => setToggled(false)}
				toggled={isToggled}
				breakPoint="all"
			>
				<Menu
					menuItemStyles={{
						button: ({ isSubmenu, level, active, disabled }) => {
							// only apply styles on first level elements of the tree
							if (level >= 0)
								return {
									color: disabled ? theme.palette.neutral.light : theme.palette.neutral.light,
									backgroundColor: active ? undefined : undefined,
									"&:hover": {
										background: theme.palette.primary.light,
										backgroundColor: theme.palette.primary.light,
									},
								};
						},
					}}
				>
					{isToggled && (
						<MenuItem
							disabled={false}
							style={{
								margin: "10px 0px 10px 0px",
								color: colors.grey[100],
								backgroundColor: "transparent",
							}}
						>
							<Box mb="30px" mt="30px">
								<Box textAlign="center" mt="">
									<Typography
										variant="h2"
										color={theme.palette.neutral.light}
										fontWeight="bold"
										sx={{ m: "10px 10 0 0" }}
									>
										{props.name}
									</Typography>
									<Typography variant="h5" color={theme.palette.secondary.main}>
										Planalto Capital
									</Typography>
								</Box>
							</Box>
						</MenuItem>
					)}

					<Box textAlign="center" mt="20px">
						<hr color={theme.palette.secondary.main}></hr>
					</Box>

					<Item title="Home" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

					{/* --------------------GESTOR--------------------------*/}

					{screens.includes("gestorCheckout") && (
						<Item
							title="Resolução de Checkout"
							to="/checkoutResolution"
							icon={<ShoppingCartOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorBaixa") && (
						<Item
							title="Baixa de Creditos"
							to="/gestorBaixa"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("gestorDocApproval") && (
						<Item
							title="Aprovação de Documentos"
							to="/gestorDocApproval"
							icon={<ContactPageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{(screens.includes("gestorLiquidacao") || screens.includes("gestorErrorPix")) && (
						<SubMenuItem label="Liquidação" icon={<PixOutlinedIcon />}>
							{screens.includes("gestorLiquidacao") && (
								<Item
									title="Liquidação Créditos"
									to="/gestorLiquidacao"
									icon={<AttachMoneyIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("gestorErrorPix") && (
								<Item
									title="Error Pix"
									to="/gestorErrorPix"
									icon={<WarningAmberOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("trades") || screens.includes("negs")) && (
						<SubMenuItem label="FIA" icon={<MonetizationOnIcon />}>
							{screens.includes("trades") && (
								<Item
									title="TRADES"
									to="/trades"
									icon={<SwapHorizontalCircleIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("negs") && (
								<Item title="NEGS" to="/negs" icon={<HandshakeIcon />} selected={selected} setSelected={setSelected} />
							)}
						</SubMenuItem>
					)}

					{/* --------------------MISC--------------------------*/}

					{screens.includes("rondologPaymentVerify") && (
						<Item
							title="Status Pagamento"
							to="/rondologPayments"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xamaPersonStatus") && (
						<Item
							title="Checagem Pessoas"
							to="/personStatus"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("rondologRomaneio") && (
						<Item
							title="Upload Romaneio"
							to="/romaneio"
							icon={<LocalShippingOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("rondologAgregado") && (
						<Item
							title="Upload Agregado"
							to="/cadastroagregado"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("cteRondolog") && (
						<Item
							title="Upload CT-e"
							to="/cte"
							icon={<ReceiptLongIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{(screens.includes("xptoFinanceiro") ||
						screens.includes("xptoFinanceiroGarantido") ||
						screens.includes("xptoPedidos")) && (
						<SubMenuItem label="Xpto" icon={<DeliveryDiningIcon />}>
							{screens.includes("xptoPedidos") && (
								<Item
									title="xpto Pedidos"
									to="/xptoPedidos"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("xptoFinanceiro") && (
								<Item
									title="xpto Financeiro"
									to="/xptoFinanceiro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("xptoFinanceiroGarantido") && (
								<Item
									title="xpto Garantido"
									to="/xptoFinanceiroGarantido"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{screens.includes("rondologDataVerification") && (
						<Item
							title="Verificação de dados"
							to="/verificacaodados"
							icon={<DomainVerificationOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("checkoutRomaneioCte") && (
						<Item
							title="Checkout"
							to="/checkout"
							icon={<ShoppingCartCheckoutOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{/* --------------------XPTO--------------------------*/}
					{screens.includes("xptoCheckout") && (
						<Item
							title="Checkout"
							to="/xptocheckout"
							icon={<DomainVerificationOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoPaymentVerify") && (
						<Item
							title="Status Pagamento"
							to="/xptoPayments"
							icon={<AccountBalanceIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoAntecipacaoViz") && (
						<Item
							title="Solicitações"
							to="/antecipacaoViz"
							icon={<ContactPageIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{screens.includes("xptoPersonStatus") && (
						<Item
							title="Checagem Pessoas"
							to="/personStatus"
							icon={<PersonOutlineIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}

					{/* --------------------InDrive--------------------------*/}

					{screens.includes("indriveRomaneioUpload") && (
						<SubMenuItem label="Upload" icon={<TwoWheelerIcon />}>
							{screens.includes("indriveRomaneioUpload") && (
								<Item
									title="InDrive Romaneio"
									to="/indriveRomaneioUpload"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("indriveCheckout") ||
						screens.includes("indriveAntecipacaoViz") ||
						screens.includes("indrivePaymentVerify")) && (
						<SubMenuItem label="Controle Fluxo" icon={<DomainVerificationOutlinedIcon />}>
							{screens.includes("indrivePaymentVerify") && (
								<Item
									title="Status Pagamento"
									to="/indrivePayments"
									icon={<AccountBalanceIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("indriveCheckout") && (
								<Item
									title="Checkout"
									to="/indriveCheckout"
									icon={<DomainVerificationOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("indriveAntecipacaoViz") && (
								<Item
									title="Solicitações"
									to="/indriveAntecipacaoViz"
									icon={<ContactPageIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("indriveGraphStatistics") ||
						screens.includes("indriveListaEntregadores") ||
						screens.includes("indrivePersonStatus")) && (
						<SubMenuItem label="Estatísticas & status" icon={<QueryStatsIcon />}>
							{screens.includes("indriveGraphStatistics") && (
								<Item
									title="Estatísticas"
									to="/indriveGraphStatistics"
									icon={<QueryStatsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("indriveListaEntregadores") && (
								<Item
									title="Lista Entregadores"
									to="/indriveListaEntregadores"
									icon={<SportsMotorsportsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("indrivePersonStatus") && (
								<Item
									title="Checagem Pessoas"
									to="/indrivePersonStatus"
									icon={<PersonOutlineIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{/* --------------------Logus--------------------------*/}

					{screens.includes("logusRomaneioUpload") && (
						<SubMenuItem label="Upload" icon={<TwoWheelerIcon />}>
							{screens.includes("logusRomaneioUpload") && (
								<Item
									title="Romaneio PickNGo"
									to="/logusRomaneioUpload"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("logusCadastroUpload") && (
								<Item
									title="Cadastro Entregadores"
									to="/logusCadastroUpload"
									icon={<TwoWheelerIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("logusCheckout") ||
						screens.includes("logusAntecipacaoViz") ||
						screens.includes("logusPaymentVerify")) && (
						<SubMenuItem label="Controle Fluxo" icon={<DomainVerificationOutlinedIcon />}>
							{screens.includes("logusPaymentVerify") && (
								<Item
									title="Status Pagamento"
									to="/logusPayments"
									icon={<AccountBalanceIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("logusCheckout") && (
								<Item
									title="Checkout"
									to="/logusCheckout"
									icon={<DomainVerificationOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("logusAntecipacaoViz") && (
								<Item
									title="Solicitações"
									to="/logusAntecipacaoViz"
									icon={<ContactPageIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("logusGraphStatistics") ||
						screens.includes("logusListaEntregadores") ||
						screens.includes("logusPersonStatus")) && (
						<SubMenuItem label="Estatísticas & status" icon={<QueryStatsIcon />}>
							{screens.includes("logusGraphStatistics") && (
								<Item
									title="Estatísticas"
									to="/logusGraphStatistics"
									icon={<QueryStatsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("logusListaEntregadores") && (
								<Item
									title="Lista Entregadores"
									to="/logusListaEntregadores"
									icon={<SportsMotorsportsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("logusPersonStatus") && (
								<Item
									title="Checagem Pessoas"
									to="/logusPersonStatus"
									icon={<PersonOutlineIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{/* --------------------Agility--------------------------*/}

					{screens.includes("agilityRomaneioUpload") && (
						<SubMenuItem label="Upload" icon={<TwoWheelerIcon />}>
							{screens.includes("agilityRomaneioUpload") && (
								<Item
									title="Agility Romaneio"
									to="/agilityRomaneioUpload"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("agilityCheckout") ||
						screens.includes("agilityAntecipacaoViz") ||
						screens.includes("agilityPaymentVerify")) && (
						<SubMenuItem label="Controle Fluxo" icon={<DomainVerificationOutlinedIcon />}>
							{screens.includes("agilityPaymentVerify") && (
								<Item
									title="Status Pagamento"
									to="/agilityPayments"
									icon={<AccountBalanceIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("agilityCheckout") && (
								<Item
									title="Checkout"
									to="/agilityCheckout"
									icon={<DomainVerificationOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("agilityAntecipacaoViz") && (
								<Item
									title="Solicitações"
									to="/agilityAntecipacaoViz"
									icon={<ContactPageIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("agilityGraphStatistics") ||
						screens.includes("agilityListaEntregadores") ||
						screens.includes("agilityPersonStatus")) && (
						<SubMenuItem label="Estatísticas & status" icon={<QueryStatsIcon />}>
							{screens.includes("agilityGraphStatistics") && (
								<Item
									title="Estatísticas"
									to="/agilityGraphStatistics"
									icon={<QueryStatsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("agilityListaEntregadores") && (
								<Item
									title="Lista Entregadores"
									to="/agilityListaEntregadores"
									icon={<SportsMotorsportsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("agilityPersonStatus") && (
								<Item
									title="Checagem Pessoas"
									to="/agilityPersonStatus"
									icon={<PersonOutlineIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{/* -------------------------------------------------------------------*/}

					{(screens.includes("rayoCheckout") ||
						screens.includes("rayoFinanceiroUpload") ||
						screens.includes("rayoCadastroUpload")) && (
						<SubMenuItem label="Rayo" icon={<TwoWheelerIcon />}>
							{screens.includes("rayoFinanceiroUpload") && (
								<Item
									title="Rayo Financeiro"
									to="/rayoFinanceiro"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("rayoCadastroUpload") && (
								<Item
									title="Rayo Cadastro"
									to="/rayoCadastro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("rayoPaymentVerify") && (
								<Item
									title="Status Pagamento"
									to="/rayoPayments"
									icon={<AccountBalanceIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("rayoCheckout") && (
								<Item
									title="Checkout"
									to="/rayocheckout"
									icon={<DomainVerificationOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("rayoAntecipacaoViz") && (
								<Item
									title="Solicitações"
									to="/rayoantecipacaoViz"
									icon={<ContactPageIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("rayoGraphStatistics") && (
								<Item
									title="Estatísticas"
									to="/rayoGraphStatistics"
									icon={<QueryStatsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("rayoListaEntregadores") && (
								<Item
									title="Lista Entregadores"
									to="/rayoListaEntregadores"
									icon={<SportsMotorsportsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("rayoPersonStatus") && (
								<Item
									title="Checagem Pessoas"
									to="/rayoPersonStatus"
									icon={<PersonOutlineIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}

					{(screens.includes("muveFinanceiro") ||
						screens.includes("muveFinanceiroGarantido") ||
						screens.includes("muvePedidos")) && (
						<SubMenuItem label="Muve" icon={<DeliveryDiningIcon />}>
							{screens.includes("muvePedidos") && (
								<Item
									title="Muve Pedidos"
									to="/muvePedidos"
									icon={<LocalShippingOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("muveFinanceiro") && (
								<Item
									title="Muve Financeiro"
									to="/muveFinanceiro"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("muveFinanceiroGarantido") && (
								<Item
									title="Muve Garantido"
									to="/muveFinanceiroGarantido"
									icon={<ReceiptLongIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}

							{screens.includes("muvePaymentVerify") && (
								<Item
									title="Status Pagamento"
									to="/muvePayments"
									icon={<AccountBalanceIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("muveCheckout") && (
								<Item
									title="Checkout"
									to="/muvecheckout"
									icon={<DomainVerificationOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("muveAntecipacaoViz") && (
								<Item
									title="Solicitações"
									to="/muveantecipacaoViz"
									icon={<ContactPageIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("muveGraphStatistics") && (
								<Item
									title="Estatísticas"
									to="/muveGraphStatistics"
									icon={<QueryStatsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("muveListaEntregadores") && (
								<Item
									title="Lista Entregadores"
									to="/muveListaEntregadores"
									icon={<SportsMotorsportsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
							{screens.includes("muvePersonStatus") && (
								<Item
									title="Checagem Pessoas"
									to="/muvePersonStatus"
									icon={<PersonOutlineIcon />}
									selected={selected}
									setSelected={setSelected}
								/>
							)}
						</SubMenuItem>
					)}
					{/* -------------------------------------------------------------------*/}
					{screens.includes("gestorWhatsapp") && (
						<Item
							title="Mensagens Whatsapp"
							to="/gestorWhatsapp"
							icon={<WhatsAppIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
					)}
					{/* <Item
            title="Faq"
            to="/faq"
            icon={<HelpOutlineOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          /> */}
				</Menu>
			</Sidebar>
		</div>
	);
};

export default Sidebar2;
