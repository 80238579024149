import React from "react";
import { Box } from "@mui/material";
import MuvePaymentVerify from "../../global/opsSpecifics/muveComponents/muvePaymentVerify";
import RayoPaymentVerify from "../../global/opsSpecifics/rayoComponents/rayoPaymentVerify";
import LogusPaymentVerify from "../../global/opsSpecifics/logusComponents/logusPaymentVerify";
import AgilityPaymentVerify from "../../global/opsSpecifics/agilityComponents/agilityPaymentVerify";

const XamaPaymentVerify = ({ ops }) => {
	return (
		<Box m="20px">
			{ops === "muve" ? (
				<MuvePaymentVerify />
			) : ops === "rayo" ? (
				<RayoPaymentVerify />
			) : ops === "logus" ? (
				<LogusPaymentVerify />
			) : ops === "agility" ? (
				<AgilityPaymentVerify />
			) : null}
		</Box>
	);
};

export default XamaPaymentVerify;
