import React from "react";
import { Box } from "@mui/material";
import MuvePersonStatus from "../../global/opsSpecifics/muveComponents/muvePersonStatus";
import RayoPersonStatus from "../../global/opsSpecifics/rayoComponents/rayoPersonStatus";
import LogusPersonStatus from "../../global/opsSpecifics/logusComponents/logusPersonStatus";
import AgilityPersonStatus from "../../global/opsSpecifics/agilityComponents/agilityPersonStatus";

const XamaPersonStatus = ({ ops }) => {
	return (
		<Box m="20px">
			{ops === "muve" ? (
				<MuvePersonStatus />
			) : ops === "rayo" ? (
				<RayoPersonStatus />
			) : ops === "logus" ? (
				<LogusPersonStatus />
			) : ops === "agility" ? (
				<AgilityPersonStatus />
			) : null}
		</Box>
	);
};

export default XamaPersonStatus;
