import { Box, Button, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export const createListMessage = (request) => {
	const { interactiveList } = request;
	const { body, action } = interactiveList;
	const { button, sections } = action;
	const { title, rows } = sections[0];

	return (
		<Box>
			{/* Corpo do texto principal */}
			<Typography variant="h4">{body.text}</Typography>

			{/* Botão com texto azul */}
			<Button
				variant="h3"
				style={{ color: "rgb(244, 247, 250)" }}
				sx={{
					border: "1px solid",
					borderRadius: "12px",
					padding: "4px 8px",
				}}
			>
				{button}
			</Button>
			<hr />

			{/* Título com uma bolinha branca e texto em negrito */}
			<Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
				{title}:
			</Typography>

			{/* Lista de títulos e descrições */}
			{rows.map((row, index) => (
				<Box key={index} sx={{ marginBottom: 1 }}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<RadioButtonUncheckedIcon fontSize="14" sx={{ marginRight: 0.5 }} />
						<Typography variant="h4">{row.title}</Typography>
					</Box>
					<Typography variant="h6" style={{ color: "rgb(156, 156, 156)" }}>
						{row.description}
					</Typography>
				</Box>
			))}
		</Box>
	);
};
