import { createListMessage } from "./createListMessage";
import { createPdfLink } from "./createPdfLink";
import { formatTime } from "./formatTime";
import { createTemplateMessage } from "./createTemplateMessage";

const handleReceivedMessage = {
	text: (messageData) => JSON.parse(messageData.S).body,
	reaction: (messageData) => `Reação: ${JSON.parse(messageData.S).emoji}`,
	button: (messageData) => JSON.parse(messageData.S).text,
	sticker: () => "sticker",
	interactive: (messageData) => {
		const parsedMessage = JSON.parse(messageData.S);
		return parsedMessage.title + "\n" + parsedMessage.description;
	},
	image: () => "imagem",
	document: (request) => "pdf",
	default: () => "Tipo de mensagem não encontrado",
};

const handleSentMessage = {
	default: () => "Tipo de mensagem não encontrado",
	list: (request) => createListMessage(request),
	interactive: (request) => request.text.body,
	text: (request) => request.text.body,
	document: (request) => createPdfLink(request.document.link),
	template: (request) => {
		const templateName = request.template.name;

		// Verificar o tipo de template e montar o texto
		switch (templateName) {
			case "oferta":
				return `${request.template.components[0].parameters[0].text} A antecipação das suas entregas ${request.template.components[0].parameters[1].text} do dia ${request.template.components[0].parameters[2].text} já está disponível. O pedido de antecipação pode ser feito até ${request.template.components[0].parameters[3].text}`;
			case "comprovante":
				return `${request.template.components[0].parameters[0].text}, o valor de ${request.template.components[0].parameters[1].text} referente à ${request.template.components[0].parameters[2].text} foi realizado com sucesso na data ${request.template.components[0].parameters[3].text}.`;
			case "trocarpix":
				return `${request.template.components[0].parameters[0].text}, o pagamento ${request.template.components[0].parameters[1].text} deu errado, por favor troque a sua chave PIX`;
			case "reprovacadastro":
				return `Seu cadastro foi reprovado! Caso tenha interesse faça novamente`;
			case "aprovacadastro":
				return `*Seu cadastro foi aprovado!* A partir do próximo dia útil você vai receber ${request.template.components[0].parameters[0].text}`;
			default:
				return `Template ${templateName} Não paraseado no FrontEnd.`;
		}
	},
};

export const handleMessages = (messagesDynamoDB, startTimestamp, endTimestamp) => {
	const formatedMessages = {};
	for (const messageDynamoDB of messagesDynamoDB) {
		if ("messageType" in messageDynamoDB === false) {
			continue;
		}
		const phone = messageDynamoDB.phone.N;
		const status = messageDynamoDB.status.S;
		const messageType = messageDynamoDB.messageType.S;
		const cursor = messageDynamoDB.cursor?.S;
		const userState = messageDynamoDB.userState?.S;
		const isReceived = status === "received";
		const request = messageDynamoDB.request ? JSON.parse(messageDynamoDB.request.S) : null;
		try {
			var message = isReceived
				? (handleReceivedMessage[messageType] || handleReceivedMessage.default)(messageDynamoDB.message)
				: (handleSentMessage[messageType] || handleSentMessage.default)(request);
		} catch (e) {
			console.error(messageDynamoDB);
			console.error(`Msg Received: ${isReceived} - Msg Type: ${messageType}`);
			throw new Error(`Erro ao formatar a mensagem: ${e.message}`);
		}

		const me = !isReceived;
		const time = formatTime(Number(messageDynamoDB.moment.N));

		if (!formatedMessages[phone]) {
			formatedMessages[phone] = {
				message: [{ me, message, time, status }],
				phone,
				cursor,
				userState,
				startTimestamp,
				endTimestamp,
			};
		} else {
			formatedMessages[phone].message.push({ me, message, time, status });
		}
	}

	return formatedMessages;
};
