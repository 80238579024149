import { apiPost } from "../../utilFunctions/apiRequests";
import { ManagerVerbs } from "../../utilFunctions/managerVerbs";
import { handleMessages } from "../../utilFunctions/handleMessages";
import { getUserState } from "./getUserState";
import { getUserCursor } from "./getUserCursor";

export const getConversation = async (
    clientName,
    phone,
    startTimestamp,
    endTimestamp,
    accessToken,
    logout,
    ops = "muve"
) => {
    const { REACT_APP_STAGE, REACT_APP_WHATSAPP_API } = process.env;

    const messagesDynamoDB = await apiPost(
        `/whatsapp-public-api-${REACT_APP_STAGE}/manager`,
        {
            clientName,
            phone,
            verb: ManagerVerbs.GetMessagesByPhoneAndTimestamp,
            start: startTimestamp,
            end: endTimestamp,
            ops: ops,
        },
        accessToken,
        logout,
        REACT_APP_WHATSAPP_API
    )
        .then((data) => data)
        .catch((err) => console.log(err));

    if (!messagesDynamoDB.length) throw new Error("Não existe mensagens");
    const formatedMessages = handleMessages(messagesDynamoDB, startTimestamp, endTimestamp);
    const conversation = Object.values(formatedMessages)[0];

    const userState = await getUserState(clientName, phone, accessToken, logout, ops);
    const userCursor = await getUserCursor(clientName, phone, accessToken, logout, ops);

    conversation.userState = userState;
    conversation.cursor = userCursor;
    return conversation;
};
